import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout' 
import ReactMarkdown from "react-markdown"  


const ThoughtTemplate = ({ data }) => (
  <Layout>
    <h1>{data.strapiThought.title}</h1>
    <Img fixed={data.strapiThought.cover.childImageSharp.fixed}/>
    <ReactMarkdown 
      source={data.strapiThought.description}
      transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`}
    />
  </Layout>
)

export default ThoughtTemplate

export const query = graphql`
  query ThoughtTemplate($id: String!) {
    strapiThought(id: {eq: $id}) {
      id
      title
      description
      cover {
          childImageSharp {
            fixed(width: 200, height: 125) {
              ...GatsbyImageSharpFixed
            }
          }
        }
    }
  }
`